var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"containerbar"}},[_c('div',{staticClass:"rightbar"},[_c('div',{staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12"},[_c('h4',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("categories.supplies")))]),_c('div',{staticClass:"breadcrumb-list"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v(_vm._s(_vm.$t("categories.home")))])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(" "+_vm._s(_vm.$t("categories.configuration"))+" ")]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))])])])])]),_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button"},[(
              _vm.showAction(1) && _vm.withPermissionName(_vm.permissions.REM_REQ_ADD_01)
            )?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(2)}}},[_c('i',{staticClass:"ti-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.create_category"))+" ")]):_vm._e(),(_vm.showAction(2) || _vm.showAction(3))?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(1)}}},[_c('i',{staticClass:"ti-arrow-left mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.return_to"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('AlertMessageComponent',{ref:"alertMessageComponent"}),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"card-title mb-0"},[_c('i',{staticClass:"ti-clipboard mr-2"}),_vm._v(_vm._s(_vm.$t(_vm.titleAction))+" ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAction(1)),expression:"showAction(1)"}]},[_c('TableComponent',{ref:"tableCat",attrs:{"headers":_vm.headers,"isButton":true,"isUpdate":_vm.withPermissionName(_vm.permissions.CAT_CAT_EDIT_02)},on:{"excelTable":_vm.excelTable,"updateRecord":_vm.edit}})],1),(_vm.showAction(2) || _vm.showAction(3))?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-2"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doAction.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("categories.info_category"))+" ")],1),_c('validation-provider',{attrs:{"name":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":255,"error-messages":errors,"label":_vm.$t('label.name'),"required":"","autocomplete":"off"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"description","rules":"required|max:300|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:[\\s][0-9A-ZÁÉÍÑÓÚÜ]+)*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","counter":300,"error-messages":errors,"label":_vm.$t('label.description'),"required":"","autocomplete":"off","maxlength":"500","rows":"3"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid || _vm.sending}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_category"))+" ")])])])]}}],null,false,1972720113)})],1)])]):_vm._e()],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }