<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("categories.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("categories.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("categories.configuration") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.REM_REQ_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i> {{ $t("global.create_category") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <!-- End Breadcrumbbar -->
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="ti-clipboard mr-2"></i>{{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>
              <div v-show="showAction(1)">
                <TableComponent
                  ref="tableCat"
                  :headers="headers"
                  :isButton="true"
                  @excelTable="excelTable"
                  @updateRecord="edit"
                  :isUpdate="withPermissionName(permissions.CAT_CAT_EDIT_02)"
                />
              </div>
              <div v-if="showAction(2) || showAction(3)">
                <div class="card">
                  <div class="card-body p-2">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="col-md-12">
                          <h6>
                            <v-icon
                              class="pb-1"
                              v-text="'mdi-numeric-1-box-outline'"
                            />
                            {{ $t("categories.info_category") }}
                          </h6>
                          <validation-provider
                            v-slot="{ errors }"
                            name="name"
                            rules="required|max:255"
                          >
                            <v-text-field
                              v-model="model.name"
                              :counter="255"
                              :error-messages="errors"
                              :label="$t('label.name')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </validation-provider>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            name="description"
                            rules="required|max:300|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:[\s][0-9A-ZÁÉÍÑÓÚÜ]+)*$"
                          >
                            <v-textarea
                              outlined
                              v-model="model.description"
                              :counter="300"
                              :error-messages="errors"
                              :label="$t('label.description')"
                              required
                              autocomplete="off"
                              maxlength="500"
                              rows="3"
                            />
                          </validation-provider>
                        </div>
                        <div class="col-md-12 text-right">
                          <button
                            class="btn btn-outline-success"
                            type="submit"
                            :disabled="invalid || sending"
                          >
                            <i class="feather icon-save mr-1"></i>
                            {{ $t("global.save_category") }}
                          </button>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import TableComponent from "@/common/table/TableComponent";
import { CATEGORIES } from "@/core/DataTableHeadersLocale";
import { UtilFront, PermissionConstants } from "@/core";
import { CategoryRequest, ExcelRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "categories.categories",
      remmisionList: [],
      list: [],
      headers: CATEGORIES(),
      search: "",
      action: 1,
      sending: false,
      model: {
        name: "",
        description: "",
      },
      notifier: {
        notifying: false,
        type: "",
        message: "",
      },
      index: 0,
      permissions: PermissionConstants,
    };
  },
  created() {
    this.listAll();
  },
  computed: {
    titleAction() {
      if (this.action == 1) {
        return "categories.categories";
      } else if (this.action == 2) {
        return "categories.register_category";
      } else if (this.action == 3) {
        return "categories.edit_category";
      }
      return "Listado";
    },
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
      } else {
        this.action = action;
      }
    },
    doAction() {
      if (this.action == 2) {
        this.add();
      } else if (this.action == 3) {
        this.update();
      }
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    listAll() {
      this.loading = UtilFront.getLoadding();
      CategoryRequest.listAll()
        .then((res) => {
          let { data } = res.data;
          if (data.objects) {
            const list = data.objects.map((object) => {
              return object;
            });
            this.$refs.tableCat.reloadComponent(list);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    add() {
      this.loading = UtilFront.getLoadding();
      this.sending = true;
      CategoryRequest.add({
        name: this.model.name,
        description: this.model.description,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: 0,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success) {
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
          this.listAll();
        });
    },
    update() {
      this.loading = UtilFront.getLoadding();
      this.sending = true;
      CategoryRequest.update({
        idCategory: this.model.idCategory,
        name: this.model.name,
        description: this.model.description,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: 0,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success) {
            this.changeAction(1);
            this.list[this.index] = this.model;
            this.alert("success", "Update_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.sending = false;
          this.listAll();
        });
    },
    edit(object) {
      this.index = object.index;
      this.model = {
        ...this.model,
        ...object,
      };
      this.changeAction(3);
    },
    withPermission(numRol) {
      return UtilFront.withPermission(numRol);
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportCategory({});
    },
  },
  watch: {
    "$i18n.locale": function() {
      this.headers = CATEGORIES();
    },
  },
  components: {
    TableComponent,
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
  },
};
</script>

<style>
.theme--light.v-messages {
  color: rgb(255 0 0);
}
</style>
